export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      allowAccess: ["*"],
      page: "auth",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      allowAccess: ["*"],
      page: "auth",
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      allowAccess: ["*"],
      page: "auth",
    },
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/pages/404.vue"),
    meta: {
      title: "Error",
      allowAccess: ["*"],
      page: "auth",
      breadcrumb: {
        vi: [
          {
            text: "",
            href: "/",
          },
        ],
        en: [
          {
            text: "",
            href: "/",
          },
        ],
      },
    },
  },
  {
    path: "/no-permission",
    name: "No permission",
    component: () => import("../views/pages/no-permission.vue"),
    meta: {
      title: "No permission",
      allowAccess: ["*"],
      page: "auth",
      breadcrumb: {
        vi: [
          {
            text: "",
            href: "/",
          },
        ],
        en: [
          {
            text: "",
            href: "/",
          },
        ],
      },
    },
  },

  {
    path: "/reset-password",
    name: "Reset password",
    component: () => import("../views/account/reset-password.vue"),
    meta: {
      title: "Reset Password",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
    },
  },

  {
    path: "/",
    name: "default",
    meta: {
      title: "Trang chủ",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
        ],
      },
    },
    component: () => import("../views/dashboard/dashboard.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
    },
    component: () => import("../views/auth/logout/basic"),
  },

  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: {
      title: "Coming Soon",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Coming soon ",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Coming soon ",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/pages/coming-soon"),
  },

  {
    path: "/profile",
    name: "profile",
    meta: {
      title: "Profile",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Thông tin tài khoản",
            href: "/profile",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Profile User",
            href: "/profile",
          },
        ],
      },
    },
    component: () => import("../views/profile-user/profile-user.vue"),
  },

  // khai báo thêm menu
  {
    path: "/dashboard-popup",
    name: "DashboardPopup",
    meta: {
      title: "DashboardPopup",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Dashboard popup",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Dashboard popup",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/dashboard-popup/listDashboardPopup.vue"),
  },
  {
    path: "/dashboard-popup/:id",
    name: "DashboardPopupItem",
    meta: {
      title: "DashboardPopupItem",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Dashboard popup",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/home",
          },
          {
            text: "Dashboard popup",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/dashboard-popup/listDashboardPopup.vue"),
  },
  {
    path: "/users/list-users",
    name: "DanhSachNguoiDung",
    meta: {
      title: "Users",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý người dùng",
            href: "/users/list-users",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager Users",
            href: "/users/list-users",
          },
        ],
      },
    },
    component: () => import("../views/users/danh-sach-nguoi-dung.vue"),
  },
  {
    path: "/users/add-user",
    name: "ThemMoiTaiKhoan",
    meta: {
      title: "Users",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý người dùng",
            href: "/users/list-users",
          },
          {
            text: "Thêm mới tài khoản",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager Users",
            href: "/users/list-users",
          },
          {
            text: "Add new user",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/users/form-nguoi-dung.vue"),
  },
  {
    path: "/users/update-user/:id",
    name: "CapNhatTaiKhoan",
    meta: {
      title: "Users",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý người dùng",
            href: "/users/list-users",
          },
          {
            text: "Cập nhật người dùng",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager Users",
            href: "/users/list-users",
          },
          {
            text: "Update user",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/users/form-nguoi-dung.vue"),
  },
  {
    path: "/users/detail-user/:id",
    name: "ChiTietTaiKhoan",
    meta: {
      title: "Users",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý người dùng",
            href: "/users/list-users",
          },
          {
            text: "Chi tiết người dùng",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager Users",
            href: "/users/list-users",
          },
          {
            text: "View user",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/users/form-nguoi-dung.vue"),
  },

  // position
  {
    path: "/position",
    name: "DanhSachChucDanh",
    meta: {
      title: "Position",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý chức danh",
            href: "/position",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager positons",
            href: "/position",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-chuc-danh/list-chuc-danh.vue"),
  },

  // quality-level
  {
    path: "/quality_level",
    name: "QualityLevel",
    meta: {
      title: "Quality Level",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quality Level",
            href: "/quality_level",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Quality Level",
            href: "/quality_level",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-quality/quality-level.vue"),
  },

  // add quality-level
  {
    path: "/quality_level/create-quality",
    name: "CreateQuality",
    meta: {
      title: "Create quality",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Create quality",
            href: "/quality_level/create-quality",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Create quality",
            href: "/quality_level/create-quality",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-quality/form-quality.vue"),
  },

  // update quality_level
  {
    path: "/quality_level/update-quality",
    name: "UpdateQuality",
    meta: {
      title: "Update quality",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Update quality",
            href: "/quality_level/update-quality",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Update quality",
            href: "/quality_level/update-quality",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-quality/form-quality.vue"),
  },

  // cấu hình quality-level
  {
    path: "/quality_level/cau-hinh-quality",
    name: "CauHinhQuality",
    meta: {
      title: "Cấu hình quality",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Cấu hình quality",
            href: "/quality_level/cau-hinh-quality",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Config quality",
            href: "/quality_level/cau-hinh-quality",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-quality/config-quality.vue"),
  },

  // table-config
  {
    path: "/table_config",
    name: "TableConfig",
    meta: {
      title: "Table name",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Table name",
            href: "/table_config",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Table name",
            href: "/table_config",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-table/table-config.vue"),
  },

  // add table config
  {
    path: "/table_config/add-table",
    name: "CreateTable",
    meta: {
      title: "Thêm bảng",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Thêm bảng",
            href: "/table_config/add-table",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Add Table",
            href: "/table_config/add-table",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-table/form-table-config.vue"),
  },

  // update table config
  {
    path: "/table_config/update-table",
    name: "UpdateTable",
    meta: {
      title: "Chỉnh sửa bảng",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Chỉnh sửa bảng",
            href: "/table_config/update-table",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Update Table",
            href: "/table_config/update-table",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-table/form-table-config.vue"),
  },

  // customer_summary
  {
    path: "/customer_summary",
    name: "CustomerSummary",
    meta: {
      title: "Cột dữ liệu khách hàng",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Cột dữ liệu khách hàng",
            href: "/customer_summary",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Customer columns",
            href: "/customer_summary",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-customer/customer-summary.vue"),
  },

  // add customer_summary
  {
    path: "/customer_summary/create",
    name: "CreateCustomer",
    meta: {
      title: "Create customer summary",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Create customer summary",
            href: "/customer_summary/create",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Create customer summary",
            href: "/customer_summary/create",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-customer/form-custom.vue"),
  },

  // update customer_summary
  {
    path: "/customer_summary/update",
    name: "UpdateCustomer",
    meta: {
      title: "Update customer summary",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Update customer summary",
            href: "/customer_summary/update",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Update customer summary",
            href: "/customer_summary/update",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-customer/form-custom.vue"),
  },

  // Service Health KPI
  {
    path: "/service_health_kpi",
    name: "ServiceHealth",
    meta: {
      title: "Service health KPI",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Service health KPI",
            href: "/service_health_kpi",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Service health KPI",
            href: "/service_health_kpi",
          },
        ],
      },
    },
    component: () =>
      import("../views/service-health-kpi/table-service-health-kpi.vue"),
  },
  // Service Health KPI view
  {
    path: "/service_health_kpi/detail/:id",
    name: "ServiceHealthDetail",
    meta: {
      title: "Detail service health KPI",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Service health KPI",
            href: "/service_health_kpi",
          },
          {
            text: "Chi tiết service health KPI",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Service health KPI",
            href: "/service_health_kpi",
          },
          {
            text: "Detail service health KPI",
            href: "",
          },
        ],
      },
    },
    component: () =>
      import("../views/service-health-kpi/form-service-health-kpi.vue"),
  },
  // Service Health KPI add
  {
    path: "/service_health_kpi/add",
    name: "ServiceHealthAdd",
    meta: {
      title: "Add service health KPI",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Service health KPI",
            href: "/service_health_kpi",
          },
          {
            text: "Thêm mới service health KPI",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Service health KPI",
            href: "/service_health_kpi",
          },
          {
            text: "Add new service health KPI",
            href: "",
          },
        ],
      },
    },
    component: () =>
      import("../views/service-health-kpi/form-service-health-kpi.vue"),
  },
  // Service Health KPI update
  {
    path: "/service_health_kpi/update/:id",
    name: "ServiceHealthUpdate",
    meta: {
      title: "Update service health KPI",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Service health KPI",
            href: "/service_health_kpi",
          },
          {
            text: "Cập nhật service health KPI",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Service health KPI",
            href: "/service_health_kpi",
          },
          {
            text: "Update service health KPI",
            href: "",
          },
        ],
      },
    },
    component: () =>
      import("../views/service-health-kpi/form-service-health-kpi.vue"),
  },

  // Time Range
  {
    path: "/time-range",
    name: "ListTimeRange",
    meta: {
      title: "Time Range",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Time range",
            href: "/time-range",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Time range",
            href: "/time-range",
          },
        ],
      },
    },
    component: () => import("../views/time-range/time-range.vue"),
  },
  {
    path: "/time-range/:id",
    name: "TimeRange",
    meta: {
      title: "Detail time range",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Time range",
            href: "/time-range",
          },
          {
            text: "Chi tiết time range",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Time range",
            href: "/time-range",
          },
          {
            text: "Detail time range",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/time-range/form-time-range.vue"),
  },
  {
    path: "/time-range/add",
    name: "TimeRangeAdd",
    meta: {
      title: "Add time range",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Time range",
            href: "/time-range",
          },
          {
            text: "Thêm mới time range",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Time range",
            href: "/time-range",
          },
          {
            text: "Add new time range",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/time-range/form-time-range.vue"),
  },
  {
    path: "/time-range/update/:id",
    name: "TimeRangeUpdate",
    meta: {
      title: "Update time range",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Time range",
            href: "/time-range",
          },
          {
            text: "Cập nhật time range",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Time range",
            href: "/time-range",
          },
          {
            text: "Update time range",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/time-range/form-time-range.vue"),
  },

  // quyền hạn
  {
    path: "/group",
    name: "NhomQuyen",
    meta: {
      title: "Quyền hạn",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quyền hạn",
            href: "/group",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Group",
            href: "/group",
          },
        ],
      },
    },
    component: () => import("../views/nhom-quyen/danh-sach-nhom-quyen.vue"),
  },
  {
    path: "/group/add-group",
    name: "ThemMoiNhomQuyen",
    meta: {
      title: "Quyền hạn",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quyền hạn",
            href: "/group",
          },
          {
            text: "Thêm mới quyền hạn",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Group",
            href: "/group",
          },
          {
            text: "Add new group",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/nhom-quyen/form-nhom-quyen.vue"),
  },
  {
    path: "/group/update-group/:id",
    name: "CapNhatNhomQuyen",
    meta: {
      title: "Quyền hạn",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quyền hạn",
            href: "/group",
          },
          {
            text: "Cập nhật quyền hạn",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Group",
            href: "/group",
          },
          {
            text: "Update group",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/nhom-quyen/form-nhom-quyen.vue"),
  },

  // quản lý đơn vị
  {
    path: "/department",
    name: "DanhSachDonVi",
    meta: {
      title: "Quản lý đơn vị",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý đơn vị",
            href: "/department",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager department",
            href: "/department",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-don-vi/danh-sach-don-vi.vue"),
  },
  {
    path: "/service/add-department",
    name: "ThemMoiDonVi",
    meta: {
      title: "Thêm mới đơn vị",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý đơn vị",
            href: "/department",
          },
          {
            text: "Thêm mới đơn vị",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager department",
            href: "/department",
          },
          {
            text: "Add new department",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-don-vi/form-don-vi.vue"),
  },
  {
    path: "/department/update-department/:id",
    name: "CapNhatDonVi",
    meta: {
      title: "Cập nhật đơn vị",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý đơn vị",
            href: "/department",
          },
          {
            text: "Cập nhật đơn vị",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager department",
            href: "/department",
          },
          {
            text: "Update department",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-don-vi/form-don-vi.vue"),
  },

  // table-config
  {
    path: "/table_screen",
    name: "TableScreen",
    meta: {
      title: "Table Screen",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Table Screen",
            href: "/table_screen",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Table Screen",
            href: "/table_screen",
          },
        ],
      },
    },
    component: () => import("../views/table-screen/table-screen-list.vue"),
  },

  // quản lý dịch vụ
  {
    path: "/service",
    name: "DanhSachDichVu",
    meta: {
      title: "Quản lý dịch vụ",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý dịch vụ",
            href: "/service",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager service",
            href: "/service",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-dich-vu/danh-sach-dich-vu.vue"),
  },
  {
    path: "/service/add-service",
    name: "ThemMoiDichVu",
    meta: {
      title: "Thêm mới dịch vụ",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý dịch vụ",
            href: "/service",
          },
          {
            text: "Thêm mới dịch vụ",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager service",
            href: "/service",
          },
          {
            text: "Add new service",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-dich-vu/form-dich-vu.vue"),
  },
  {
    path: "/service/update-service/:id",
    name: "CapNhatDichVu",
    meta: {
      title: "Cập nhật dịch vụ",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý dịch vụ",
            href: "/service",
          },
          {
            text: "Cập nhật dịch vụ",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager service",
            href: "/service",
          },
          {
            text: "Update service",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-dich-vu/form-dich-vu.vue"),
  },
  {
    path: "/service/config-display-service",
    name: "CauHinhHienThiDichVu",
    meta: {
      title: "Cấu hình hiển thị dịch vụ",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý dịch vụ",
            href: "/service",
          },
          {
            text: "Cấu hình hiển thị dịch vụ",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Manager service",
            href: "/service",
          },
          {
            text: "Config display service",
            href: "",
          },
        ],
      },
    },
    component: () =>
      import("../views/quan-ly-dich-vu/cau-hinh-hien-thi-dich-vu.vue"),
  },
  // router cho quản lý dịch vụ ở đây

  // danh mục hành chính
  {
    path: "/dvhc/tinh",
    name: "dvhcTinh",
    meta: {
      title: "Danh mục tỉnh",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Danh sách tỉnh",
            href: "/dvhc/tinh",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Province",
            href: "/dvhc/tinh",
          },
        ],
      },
    },
    component: () => import("../views/don-vi-hanh-chinh/dvhc-tinh.vue"),
  },
  {
    path: "/dvhc/huyen",
    name: "dvhcHuyen",
    meta: {
      title: "Danh mục huyện",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Danh sách huyện",
            href: "/dvhc/huyen",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "District",
            href: "/dvhc/huyen",
          },
        ],
      },
    },
    component: () => import("../views/don-vi-hanh-chinh/dvhc-huyen.vue"),
  },
  {
    path: "/dvhc/xa",
    name: "dvhcXa",
    meta: {
      title: "Danh mục xã",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Danh sách xã",
            href: "/dvhc/xa",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Commune",
            href: "/dvhc/xa",
          },
        ],
      },
    },
    component: () => import("../views/don-vi-hanh-chinh/dvhc-xa.vue"),
  },

  {
    path: "/probe/add-probe",
    name: "ThemMoiTram",
    meta: {
      title: "Probe",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Thêm trạm",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Add new probe",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-tram/form-tram.vue"),
  },
  {
    path: "/probe/update-probe/:id",
    name: "CapNhatTram",
    meta: {
      title: "Probe",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary", "AdminLocal", "Edit"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Cập nhật trạm",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Update probe",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-tram/form-tram.vue"),
  },
  {
    path: "/probe/setting-probe",
    name: "DanhSachProbeColumn",
    meta: {
      title: "Probe",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý trạm",
            href: "",
          },
          {
            text: "Cấu hình thông tin trạm",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Setting probe",
            href: "",
          },
          {
            text: "Configure station information",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-tram/cau-hinh-tram.vue"),
  },
  {
    path: "/probe/setting-probe/add-setting-probe",
    name: "ThemMoiCauHinhTram",
    meta: {
      title: "Probe",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý trạm",
            href: "",
          },
          {
            text: "Thêm mới cấu hình trạm",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Setting probe",
            href: "",
          },
          {
            text: "Add new setting probe",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-tram/form-cau-hinh-tram.vue"),
  },
  {
    path: "/probe/setting-probe-icon",
    name: "DanhSachProbeIcon",
    meta: {
      title: "Probe",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý trạm",
            href: "",
          },
          {
            text: "Cấu hình icon",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Setting probe",
            href: "",
          },
          {
            text: "Configure icon",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-tram/cau-hinh-icon.vue"),
  },
  {
    path: "/probe/update-setting-probe/:id",
    name: "CapNhatCauHinhTram",
    meta: {
      title: "Probe",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý trạm",
            href: "",
          },
          {
            text: "Cập nhật cấu hình trạm",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Setting probe",
            href: "",
          },
          {
            text: "Update setting probe",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-tram/form-cau-hinh-tram.vue"),
  },
  {
    path: "/probe/detail-setting-probe/:id",
    name: "ChiTietCauHinhTram",
    meta: {
      title: "Probe",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Quản lý trạm",
            href: "",
          },
          {
            text: "Chi tiết cấu hình trạm",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Setting probe",
            href: "",
          },
          {
            text: "Detail setting probe",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-tram/form-cau-hinh-tram.vue"),
  },
  {
    path: "/setting",
    name: "CauHinhChung",
    meta: {
      title: "Setting",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Cấu hình chung",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Setting",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/cau-hinh-chung/cau-hinh-chung.vue"),
  },
  {
    path: "/guide",
    name: "HuongDanSuDung",
    meta: {
      title: "Guide",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Hướng dẫn sử dụng",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Guide",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/huong-dan-su-dung/guides.vue"),
  },
  {
    path: "/guide/add-guide",
    name: "ThemHuongDanSuDung",
    meta: {
      title: "Guide",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Hướng dẫn sử dụng",
            href: "/guide",
          },
          {
            text: "Thêm hướng dẫn sử dụng",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Guide",
            href: "/guide",
          },
          {
            text: "Add guide",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/huong-dan-su-dung/form-guide.vue"),
  },
  {
    path: "/guide/update-guide/:id",
    name: "CapNhatHuongDanSuDung",
    meta: {
      title: "Guide",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Hướng dẫn sử dụng",
            href: "/guide",
          },
          {
            text: "Cập nhật hướng dẫn sử dụng",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Guide",
            href: "/guide",
          },
          {
            text: "Update guide",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/huong-dan-su-dung/form-guide.vue"),
  },
  {
    path: "/guide/view-guide/:id",
    name: "XemHuongDanSuDung",
    meta: {
      title: "Guide",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Hướng dẫn sử dụng",
            href: "/guide",
          },
          {
            text: "Xem hướng dẫn sử dụng",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Guide",
            href: "/guide",
          },
          {
            text: "See guide",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/huong-dan-su-dung/view-guide.vue"),
  },
  {
    path: "/list-download",
    name: "ListDownload",
    meta: {
      title: "Guide",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Danh sách tải xuống",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "List download",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/danh-sach-tai-card/danh-sach-tai.vue"),
  },
  {
    path: "/list-download",
    name: "ListDownload",
    meta: {
      title: "Guide",
      authRequired: true,
      allowAccess: ["*"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Danh sách tải xuống",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "List download",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/danh-sach-tai-card/danh-sach-tai.vue"),
  },
  {
    path: "/menu/list-menu",
    name: "ListMenu",
    meta: {
      title: "Menu",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Danh sách menu",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Menu config",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/menu/danh-sach-menu.vue"),
  },
  {
    path: "/menu/create-menu",
    name: "ThemMoiMenu",
    meta: {
      title: "Thêm mới menu",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Cấu hình menu",
            href: "/list-menu",
          },
          {
            text: "Thêm mới",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Menu config",
            href: "/list-menu",
          },
          {
            text: "Create",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/menu/form-menu.vue"),
  },
  {
    path: "/menu/update-menu/:id",
    name: "CapNhatMenu",
    meta: {
      title: "Cập nhật menu",
      authRequired: true,
      allowAccess: ["Admin"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Cấu hình menu",
            href: "/list-menu",
          },
          {
            text: "Cập nhật",
            href: "",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Menu config",
            href: "/list-menu",
          },
          {
            text: "Update",
            href: "",
          },
        ],
      },
    },
    component: () => import("../views/menu/form-menu.vue"),
  },
  {
    path: "/log",
    name: "DanhSachLog",
    meta: {
      title: "Danh sách log",
      authRequired: true,
      allowAccess: ["Admin", "AdminPrimary"],
      page: "admin",
      breadcrumb: {
        vi: [
          {
            text: "Trang chủ",
            href: "/",
          },
          {
            text: "Danh sách log",
            href: "/log",
          },
        ],
        en: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Log list",
            href: "/log",
          },
        ],
      },
    },
    component: () => import("../views/quan-ly-log/list-log.vue"),
  },
];
